import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import "./contact.module.css";


const ContactDetails = props => {
  
  const data = useStaticQuery(graphql`
      query ContactDetailsQuery {
          site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
            contactDetails {
              phone 
              email
              instagram
              street
              suburb
              city
              postCode
              country
            }
          }
        }
      `)


  const site = (data || {}).site;


  return (
 
        <contact>
                <span><a href={site.contactDetails.email}>Email</a></span>
                <p>
                <span>{site.contactDetails.phone}</span>
                </p>
                <span>{site.contactDetails.street}</span>
                <span>{site.contactDetails.suburb}</span>
                <span>{site.contactDetails.city}</span>
                <span>{site.contactDetails.postCode}</span>
                <span>{site.contactDetails.country}</span>
        </contact>
  

  );
};

export default ContactDetails;
