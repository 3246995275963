import React from "react";

import * as styles from "./footer.module.css";

const Footer = ({children}) => (
  <>

    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
              {children}

      </div>
    </footer>
  </>
);

export default Footer;

/*         <div className={styles.siteInfo}>
          © {new Date().getFullYear()} Studio Rota.
        </div>
*/