import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => {

  const data = useStaticQuery(graphql`
  query HeaderQuery {
      site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
        services
      }
    }
  `)

  const site = (data || {}).site;


  return (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <span><Link to="/">{siteTitle}</Link></span>
        <span>{site.services}</span>

      </div>




    </div>
  </div>
)};

/*      
Navigation add below /button
<nav className={cn(styles.nav, showNav && styles.showNav)}>
<ul>
<li>
  <Link to="/archive/">Archive</Link>
</li>
</ul>
</nav>*/

export default Header;
